import './home.scss';
import '../../assets/styles/main.scss';
import '../../assets/styles/essence.scss';
import '../../assets/styles/about.scss';
import '../../assets/styles/process.scss';
import '../../assets/styles/products.scss';
import '../../assets/styles/work.scss';
import '../../assets/styles/contact.scss';
import '../../assets/styles/video.scss';

import '../../assets/photo-swipe/photoswipe.css';
import '../../assets/photo-swipe/default-skin/default-skin.css';

const $contactForm = $('form#contact-form'), url = 'https://script.google.com/macros/s/AKfycby1093uDoxKRDi_V3a6Ttq3NSQYqagEpezAQrYgYNz_uDyTDZbD/exec';;
$contactForm.validate({
  errorPlacement: function(error, element) {},
});


import categories from '../../assets/js/categories';

function getFormData($form) {
  const unIndexed_array = $form.serializeArray();
  const indexed_array = {};

  $.map(unIndexed_array, function(n, i){
    indexed_array[n['name']] = n['value'];
  });

  return indexed_array;
}

$('#submit-contact-form').click(function(e) {
  e.preventDefault();
  if ($contactForm.valid()) {
    $("#submit-contact-form").text('Cargando...');
    $.ajax({
      url: url,
      method: "GET",
      dataType: "json",
      data: {...getFormData($contactForm), Date: new Date()},
      success: function (res) {
        $('#contact-thanks').appendTo('body').modal({
          fadeDuration: 250
        });
        $contactForm[0].reset();
        $("#submit-contact-form").text('Enviar');
      }
    })
  } else {
    var $inputs = $('#contact-form :input');
    $inputs.each(function() {
      var input = $(this);
      if (!input.valid()) {
        input.addClass('error');
      } else {
        input.removeClass('error');
      }
    });
  }
});

$('#play-btn').click(function(e) {
  e.preventDefault();
  $('.video-container').removeClass('hide');
  const video = document.getElementById("video-source");
  video.play();
});

$('#close-video').click(function(e) {
  e.preventDefault();
  $('.video-container').addClass('hide');
  const video = document.getElementById("video-source");
  video.pause();
});

$(document).ready(function() {


  categories.forEach(category => {
    $('#products-row').append(
        `
        <a href="./category.html?code=` + category.code + `" class="product">
          <div class="img-box">
            <img alt="` + category.name + `" src="` + category.image + `">
          </div>
          <span class="allerta">` + category.name + `</span>
        </a>
        `
    )
  });

  require([
    '../../assets/photo-swipe/photoswipe.js',
    '../../assets/photo-swipe/photoswipe-ui-default.js'
  ], function( PhotoSwipe, PhotoSwipeUI_Default ) {

    var container = [];

    // Loop over gallery items and push it to the array
    $('#gallery').find('figure').each(function() {
      var $link = $(this).find('a'),
          item = {
            src: $link.attr('href'),
            w: $link.data('width'),
            h: $link.data('height'),
            title: $link.data('caption')
          };
      container.push(item);
    });

    // Define click event on gallery item
    $('.gallery-ref').click(function(event) {

      // Prevent location change
      event.preventDefault();

      // Define object and gallery options
      var $pswp = $('.pswp')[0],
          options = {
            index: $(this).parent('figure').index(),
            bgOpacity: 0.85,
            showHideOpacity: true,

          };

      // Initialize photo-swipe
      var gallery = new PhotoSwipe($pswp, PhotoSwipeUI_Default, container, options);
      gallery.init();
    });

  });

});
